var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.propertyType.propertyTypeImagePath,
            _vm.propertyType.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.propertyType.propertyTypeImageIsDefault &&
          _vm.checkPrivilege(_vm.hasPropertyTypeDeleteImage())},on:{"changeValue":function($event){_vm.propertyType.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.propertyType.fullCode,"title":_vm.$t('PropertyTypes.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.propertyType.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-propertyTypeNameAr"),"errors":_vm.errors_propertyTypeNameAr,"value":_vm.propertyType.propertyTypeNameAr,"title":_vm.$t('PropertyTypes.nameAr'),"imgName":'PropertyTypes.svg'},on:{"changeValue":function($event){_vm.propertyType.propertyTypeNameAr = $event;
            _vm.$v.propertyType.propertyTypeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-propertyTypeNameEn"),"errors":_vm.errors_propertyTypeNameEn,"value":_vm.propertyType.propertyTypeNameEn,"title":_vm.$t('PropertyTypes.nameEn'),"imgName":'PropertyTypes.svg'},on:{"changeValue":function($event){_vm.propertyType.propertyTypeNameEn = $event;
            _vm.$v.propertyType.propertyTypeNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-propertyTypeNotes"),"value":_vm.propertyType.propertyTypeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.propertyType.propertyTypeNotes = $event}}})],1)]),_c('PropertyFileds',{attrs:{"propertyDetailsData":_vm.propertyType.propertyDetailsData,"dropDownListTokenOptions":_vm.dropDownListTokenOptions,"id":_vm.id}}),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }